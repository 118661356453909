import React from "react";
import Layout from "../../components/layout/layout";
import SEO from "../../components/layout/seo";
import PatientTerms from "../../components/patientTerms";

export default function PatientTermsPage() {
  return (
    <Layout>
      <SEO title="Patient Terms Page" />
      <PatientTerms />
    </Layout>
  );
}
