import React from "react";
import ReactHtmlParser from "react-html-parser";
export default function PatientTermsContent({
  intro_paragraph,
  use_of_the_service,
  changes_to_the_terms_of_service,
  user_content,
  your_license_to_the_provider,
  the_providers_licenses_to_you,
  rules,
  proprietary_rights,
  copyright_policy,
  privacy,
  links,
  answers_to_medical_questions,
  indemnity_disclaimers,
  questions_related_to_medicine,
  open_source_licenses,
  general_terms,
  third_party_beneficiaries_medical_contributions,
}) {
  const converthtml = function (input) {
    var ret = input.replace(/&gt;/g, ">");
    ret = ret.replace(/&lt;/g, "<");
    ret = ret.replace(/&quot;/g, '"');
    ret = ret.replace(/&apos;/g, "'");
    ret = ret.replace(/&amp;/g, "&");
    ret = ret.replace(/&nbsp;/g, "");

    return ret;
  };

  return (
    <div className="col-12">
      {ReactHtmlParser(converthtml(intro_paragraph))}
      {ReactHtmlParser(converthtml(use_of_the_service))}
      {ReactHtmlParser(converthtml(changes_to_the_terms_of_service))}
      {ReactHtmlParser(converthtml(user_content))}
      {ReactHtmlParser(converthtml(your_license_to_the_provider))}
      {ReactHtmlParser(converthtml(the_providers_licenses_to_you))}
      {ReactHtmlParser(converthtml(rules))}
      {ReactHtmlParser(converthtml(proprietary_rights))}
      {ReactHtmlParser(converthtml(copyright_policy))}
      {ReactHtmlParser(converthtml(privacy))}
      {ReactHtmlParser(converthtml(links))}
      {ReactHtmlParser(
        converthtml(third_party_beneficiaries_medical_contributions)
      )}
      {ReactHtmlParser(converthtml(questions_related_to_medicine))}
      {ReactHtmlParser(converthtml(answers_to_medical_questions))}
      {ReactHtmlParser(converthtml(indemnity_disclaimers))}
      {ReactHtmlParser(converthtml(open_source_licenses))}
      {ReactHtmlParser(converthtml(general_terms))}
    </div>
  );
}
