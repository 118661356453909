import React from "react";
import "./assets/style.scss";
import PatientTermsContent from "./patientTermsContent";
import { useStaticQuery, graphql } from "gatsby";
export default function PatientTerms() {
  const data = useStaticQuery(graphql`
    {
      allCosmicjsPatientTermsOfServices {
        edges {
          node {
            slug
            metadata {
              intro_paragraph
              use_of_the_service
              changes_to_the_terms_of_service
              user_content
              your_license_to_the_provider
              the_providers_licenses_to_you
              rules
              third_party_beneficiaries_medical_contributions
              proprietary_rights
              copyright_policy
              privacy
              links
              answers_to_medical_questions
              questions_related_to_medicine
              indemnity_disclaimers
              open_source_licenses
              general_terms
            }
            slug
          }
        }
      }
    }
  `);

  var content_term_patient;

  data.allCosmicjsPatientTermsOfServices.edges.map((x, y) => {
    switch (x.node.slug) {
      case "patient-terms-of-service":
        content_term_patient = x.node.metadata;
        break;
    }
  });
  return (
    <div className="terms-detail">
      <div className="content">
        <div className="row">
          <PatientTermsContent
            intro_paragraph={content_term_patient.intro_paragraph}
            use_of_the_service={content_term_patient.use_of_the_service}
            changes_to_the_terms_of_service={
              content_term_patient.changes_to_the_terms_of_service
            }
            user_content={content_term_patient.user_content}
            your_license_to_the_provider={
              content_term_patient.your_license_to_the_provider
            }
            the_providers_licenses_to_you={
              content_term_patient.the_providers_licenses_to_you
            }
            rules={content_term_patient.rules}
            proprietary_rights={content_term_patient.proprietary_rights}
            copyright_policy={content_term_patient.copyright_policy}
            privacy={content_term_patient.privacy}
            links={content_term_patient.links}
            answers_to_medical_questions={
              content_term_patient.answers_to_medical_questions
            }
            indemnity_disclaimers={content_term_patient.indemnity_disclaimers}
            open_source_licenses={content_term_patient.open_source_licenses}
            general_terms={content_term_patient.general_terms}
            third_party_beneficiaries_medical_contributions={
              content_term_patient.third_party_beneficiaries_medical_contributions
            }
            questions_related_to_medicine={
              content_term_patient.questions_related_to_medicine
            }
          />
        </div>
      </div>
    </div>
  );
}
